@import '__importable.scss';
.content {
	padding: 0 1.6rem 1.6rem;

	div[class^="ComponentLayout_"] {
		padding-inline: 0;
		display: flex;
		flex-direction: column;
		gap: 0.8rem;

		@media screen and (min-width: $media-sm) {
			display: grid;
			grid-template-columns: repeat(3, 1fr); // explore ways to remove hardcoding
			align-items: start;
		}

		div[class^="ImageTitleTextStack_image"] {
			height: auto;
		}
		div[class^="ImageTitleTextStack_content"] {
			text-align: left;
		}
	}

	.header {
		position: sticky;
		top: 0;
		right: 0;
		z-index: $zindex-9;
		background-color: $white;
		padding: 1.6rem 0 0.8rem;
	}
}

.buttons {
	position: sticky;
	bottom: 0;
	background: $gray-0;
	padding: 1.6rem;
}
