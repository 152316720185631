@import '__importable.scss';
.container {
	height: 51.2rem;
	width: 32rem;
	border-radius: $radius-2;
	justify-content: flex-end;
	overflow: hidden;
	background-color: black;
	position: relative;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		width: 100%;
		min-width: 41.6rem;

		&--asPlp {
			height: 100%;
			min-width: auto;
		}
	}

	&--asHero {
		border-radius: 0;
		width: 100%;
		height: 12.8rem;

		&--useBigHero {
			height: 25.6rem;
		}

		@media screen and (min-width: $media-sm) {
			height: 25.6rem;
		}

		.content {
			@include product-grid-padding($baseBlockPadding: 3.2rem);
		}
		.header {
			@include product-grid-padding($baseBlockPadding: 1.6rem);
		}

	}

	&--fixHeight {
		height: 25.6rem;
	}

	&--asPlp {
		width: 100%;
	}

	&--asValueProp {
		height: 43.5rem;
		width: 100%;
	}

	&--micro {
		height: 12rem;
		min-width: 24rem;

		.header {
			padding: 2.4rem;
		}
	}

	&--flexible {
		width: 100%;

		h3 {
			font-size: 2.8rem;
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
		}

		h4 {
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
		}
	
		h5 {
			font-size: 1.4rem;
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 0px 4px rgba(0, 0, 0, 0.32);
			font-weight: 400;
			font-family: var(--poppins);
		}
	
		@media screen and (min-width: $media-md-max) {
			height: 100%;
			min-width: auto;
			min-height: 51.2rem;
		}
	}

	video-js {
		background-image: var(--videoPoster);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}

.gradientOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
	z-index: $zindex-2;

	&--asHero {
		background: linear-gradient(201deg, rgba(0, 0, 0, 0.00) 52.60%, rgba(0, 0, 0, 0.13) 76.30%, rgba(0, 0, 0, 0.50) 100%);
	}
}

.bgImage {
	z-index: $zindex-1;
	position: absolute;
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.bgVideo {
	z-index: $zindex-1;
	position: absolute;
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.content {
	color: $gray-0;
	z-index: $zindex-3;
	padding: 2.4rem;
	height: calc(100% + 1.6rem); // add a little bit of offset for smoother spring motions
	width: 100%;
	position: absolute;

	&--asHero {

		* {
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
		}

		@media (min-width: $media-xl) {
			padding: 2.4rem 3.2rem;
		}

		@media (min-width: $media-xxl) {
			padding: 2.4rem 6.4rem;
		}

		@media (min-width: $media-xxxl) {
			padding: 2.4rem 12.8rem;
		}
	}
	>* {
		width: calc(100% - 5rem);
	}
	.body{
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
    	-webkit-box-orient: vertical;
	}
}

.title {
	padding-bottom: 0.8rem;
	padding-right: 0.8rem;
}

.contentBg {
	position: absolute;
	width: 100%;
	z-index: $zindex-3;
}

.expandBtn {
	position: absolute;
	z-index: $zindex-3;
	width: 3.2rem;
	height: 3.2rem;
	right: 2.4rem;
	bottom: 2.4rem;
	background: $gray-0;
	border-radius: 1.6rem;
	display: flex;
	align-items: center;
	justify-content: center;

	&--asHero {
		@media (min-width: $media-xxxl) {
			right: 6.4rem;
		}
	}
}

.button {
	position: absolute;
	z-index: $zindex-3;
	right: 2.4rem;
	bottom: 2.4rem;
}

.copyrightInfo {
	position: absolute;
	bottom: 1.6rem;
	left: 1.6rem;
	z-index: $zindex-3;
	color: $gray-0;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);

	&--withExpandBtn {
		width: calc(100% - 7rem);
	}
}

.header {
	color: $gray-0;
	z-index: $zindex-3;
	width: 100%;
	position: absolute;

	@include product-grid-padding($baseBlockPadding: 2.4rem, $baseInlinePadding: 0.6rem, $removePaddingXXL: true);
	padding-inline: 2.4rem;

	[class*='Heading'] {
		width: 100%;
	}

	h4 {
		text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
	}
}

.subheading {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 2.4rem;
}

.buttons {
	position: absolute;
	z-index: $zindex-3;
	display: flex;
	padding: 0.8rem 0rem 0rem;
	justify-content: flex-start;
	flex-flow: row nowrap;
	gap: 0.8rem;
}

.description {
	font-size: 1rem !important;
	font-weight: 500 !important;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 0px 4px rgba(0, 0, 0, 0.32) !important;
	max-width: 46%;
	line-height: unset !important;

	@media screen and (min-width: $media-xxxl) {
		max-width: 40%;
	}

	@media screen and (min-width: $max-width) {
		max-width: 18%;
	}
}
