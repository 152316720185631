@import '__importable.scss';
.howItWorks {
	section {
		padding: 1.6rem;

		> *:nth-child(2) {
			// Flex container
			flex-direction: row;

			> div:nth-child(5),
			> div:nth-child(even) {
				// plus sign, equals sign, third gif
				display: none;

				@media screen and (min-width: $media-sm) {
					display: block;
				}
			}

			> div {
				&::before {
					height: 32px;
					width: 32px;
					background-color: $blue-tint;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					border-radius: 50%;
					font-size: 2rem;
					position: absolute;
				}
			}

			> div:nth-child(1) {
				&::before {
					content: '1';
				}
			}
			> div:nth-child(2) {
				&::before {
					content: '2';
				}
			}
			> div:nth-child(3) {
				&::before {
					content: '3';
				}
			}
		}
	}
}

.no-results {
	text-align: center;
	grid-column: 1 / -1;
	padding: 5.6rem 0.8rem;
}

.compareContainer {
	padding: 0 0.8rem;
	margin: auto;
	max-width: $max-width;

	@media screen and (min-width: $media-md-max) {
		padding: 0 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding: 0 12.8rem;
	}
}

.button-container {
	width: 100%;
	padding: 0.8rem 0 !important;
}

.header {
	gap: 0;
	padding: 1.6rem;
}
