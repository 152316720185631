@import '__importable.scss';
.container {
	overflow: hidden;
	min-height:32rem;
}

.swiperContainer {
	background-color: $gray-1;
	margin: 0 auto;
	width: 100%;
	border: 1px solid $gray-2;
	border-radius: 1rem;
}

.swiper {
	position: relative;
	padding: 2rem 0 7rem;
	width: 100%;
}

@media screen and (min-width: $media-sm) {
	.swiper:before,
	.swiper:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 10rem;
		background: linear-gradient(to right, rgba(249, 249, 249, 1), rgba(249, 249, 249, 1), rgba(249, 249, 249, 1), rgba(249, 249, 249, 0.8),rgba(249, 249, 249, 0));
		z-index: 2;
	}

	.swiper:before {
		left: 0;
		top: 0.8rem;
		bottom: 0.8rem;
	}

	.swiper:after {
		right: 0;
		top: 0.8rem;
		bottom: 0.8rem;
		transform: scaleX(-1)
	}
}

.prevButton {
	@include navigationButton;
	top: 50%;
	left: 1.6rem;
	z-index: 3;
}

.nextButton {
	@include navigationButton;
	top: 50%;
	right: 1.6rem;
	z-index: 3;
}

.bottomPrevButton {
	z-index: $zindex-4;
	height: 3.2rem;
	width: 3.2rem;
	border-radius: 100%;
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1.5px solid $gray-2;
	user-select: none;

	@media (hover: hover) {
		&:hover {
			background-color: $gray-2;
			transition: background-color 0.3s ease;
		}
	}
}

.bottomNextButton {
	z-index: $zindex-4;
	height: 3.2rem;
	width: 3.2rem;
	border-radius: 100%;
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1.5px solid $gray-2;
	user-select: none;

	@media (hover: hover) {
		&:hover {
			background-color: $gray-2;
			transition: background-color 0.3s ease;
		}
	}
}

.swiper-slide {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: $gray-1 !important;
	transition: opacity 0.5s ease-in-out;

	img {
		width: 250px;
		height: auto;
		mix-blend-mode: multiply;
	}
}

.swiper-slide-active {
	opacity: 1;
}

.swiper-slide-inactive {
	opacity: 0.5;
}


.hide {
	display: none;
}

.disabled {
	background-color: $gray-2;
	cursor: unset;
}

.swiper-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	transition: 300ms ease opacity;
	transform: translate3d(0, 0, 0);
	top: 0 !important;
	bottom: 0 !important;
	z-index: $zindex-1;

	&.swiper-pagination-hidden {
		opacity: 0;
	}
}

.swiper-pagination-bullet {
	height: 2.8rem;
	display: flex;
	justify-content: space-between;
	border-radius: $radius-round;
	align-items: center;
	position: relative;

	&::after {
		content: '';
		width: 0.2rem;
		height: 0.2rem;
		display: block;
		background: transparent;
		position: relative;
	}

	&:only-child {
		display: none;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&.swiper-pagination-bullet-active::after {
		opacity: inherit;
		background-color: $blue-2;
		transition: opacity 200ms ease;
		width: 2rem;
		height: 2rem;
		display: block;
		border-radius: $radius-round;
	}
}

.scaleContainer {
	position: inherit;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	gap: 0.8rem;
	z-index: 3;
}

.progressBarContainer {
	position: relative;
	width: 100%;
}

.progressLineContainer {
	position: absolute;
	overflow: hidden;
	max-width: 100%;
	display: flex;
	gap: 0.8rem;
	align-items: center;
}

.progressLine {
	min-width: 2rem;
	height: 0.2rem;
	background-color: $gray-4;
}

.progressText {
	font-size: 1.4rem;
	color: $gray-4;
	font-weight: 500;
}

.selectedContainer {
	position: relative;

	&.checkmark::before {
		content: '';
			position: absolute;
			top: 3px;
			left: -12px;
			transform: rotate(45deg);
			height: 12px;
			width: 6px;
			border-bottom: 2px solid $green-3;
			border-right: 2px solid $green-3;
	}
}

.selectedText {
	color: $gray-4;
}

.contentContainer {
	position: relative !important;
	width: 100%;
	padding: 1.2rem 0.8rem;
}

.active-slide-with-tags {
	background-color: $white;
	border: 1px solid $gray-2;
	border-radius: $radius-6;
}

.tagsContainer {
	left: 0.8rem;
}